import React, { useCallback, useEffect, useRef, useState } from "react";
import { List, SpinLoading, Space } from "antd-mobile";
import { useParams } from "react-router-dom";

const FlowResponse = () => {
  const { flowId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [flowData, setFlowData] = useState(null);
  const [error, setError] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const listRef = useRef(null);
  // Fetch flow data by flowId
  const fetchSelectedFlow = useCallback(async (flowId) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(
        `https://connectby.io:3001/api/get-flow-response?flowId=${flowId}`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setFlowData(data?.flowData);
    } catch (error) {
      setError("No data");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch flow data on component mount
  useEffect(() => {
    if (flowId) {
      fetchSelectedFlow(flowId);
    }
  }, [flowId, fetchSelectedFlow]);

  // Process raw flow data
  const processFlowData = (data) => {
    return data.map((response) => {
      const responsess = JSON.parse(response.flow_response);
      return Object.keys(responsess)
        .filter(
          (key) => key !== "flow_token" && typeof responsess[key] !== "boolean"
        )
        .reduce((acc, key) => {
          acc[key] =
            typeof responsess[key] === "string" && responsess[key].includes("_")
              ? responsess[key].split("_").slice(1).join("_").replace(/_/g, " ")
              : responsess[key];
          return acc;
        }, {});
    });
  };

  // Generate columns based on processed flow data
  const generateColumns = (data) => {
    const firstResponse = data[0];
    return Object.keys(firstResponse).map((key) => ({
      title: key.slice(9, -2).replace(/_/g, " "),
      dataIndex: key,
      key,
    }));
  };

  // Process flow data and generate columns when flowData is updated
  useEffect(() => {
    if (flowData && flowData.length > 0) {
      const processedData = processFlowData(flowData);
      const columns = generateColumns(processedData);
      setAllColumns(columns);
      setVisibleColumns(columns.map((col) => col.key));
    }
  }, [flowData]);

  // Show loading spinner when data is being fetched
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinLoading color="primary" />
      </div>
    );
  }

  // Show error message or empty state when no data is available
  if (error || !flowData || flowData.length === 0) {
    return (
      <div>
        <Space
          direction="vertical"
          block
          align="center"
          style={{ marginBottom: "16px" }}
        >
          <h3>Flow Response</h3>
        </Space>
        <List header="No Data Available" />
      </div>
    );
  }

  const processedData = processFlowData(flowData);

  // Render the list of data with visible columns
  return (
    <div style={{ height: "calc(100vh - 50px)" }}>
      <div
        ref={listRef}
        style={{ height: "calc(100vh - 50px)", overflowY: "scroll" }}
      >
        <List>
          {processedData.map((item, index) => (
            <List.Item key={index}>
              {visibleColumns.map((key) => (
                <div key={key} style={{ marginBottom: "8px" }}>
                  <strong>
                    {allColumns.find((col) => col.key === key)?.title}:{" "}
                  </strong>
                  {item[key] || "N/A"}
                </div>
              ))}
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

export default FlowResponse;
