import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { List, SearchBar, Toast, Skeleton } from "antd-mobile";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const getTimeFromDate = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPM = hours >= 12 ? "PM" : "AM";
  const twelveHourFormat = hours % 12 || 12;

  const hours12 = twelveHourFormat.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");

  return `${hours12}:${minutesStr} ${amPM}`;
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (date >= today) {
    if (date >= tomorrow)
      return date.toLocaleDateString("en-US", { weekday: "long" });
    return getTimeFromDate(date);
  } else if (date >= yesterday) return "Yesterday";
  if (date >= today - 6 * 24 * 60 * 60 * 1000)
    return date.toLocaleDateString("en-US", { weekday: "long" });
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

const truncateNotes = (notes) =>
  notes.length > 17 ? notes.substring(0, 17) + "..." : notes;

const getStatusStyles = (status) => {
  switch (status) {
    case "DRAFT":
      return { color: "#FF5722", backgroundColor: "#ffe4dc", text: "Draft" };
    case "PUBLISHED":
      return {
        color: "#0a7e0f",
        backgroundColor: "#b8ffbb",
        text: "Published",
      };
    case "DEPRECATED":
      return {
        color: "#9e9e9e",
        backgroundColor: "#f5f5f5",
        text: "Deprecated",
      };
    case "BLOCKED":
      return { color: "#f44336", backgroundColor: "#ffe6e6", text: "Blocked" };
    case "THROTTLED":
      return {
        color: "#ff9800",
        backgroundColor: "#fff3e0",
        text: "Throttled",
      };
    default:
      return { color: "#000000", backgroundColor: "#ffffff", text: "Unknown" };
  }
};

const SkeletonItem = () => (
  <List.Item
    className="teamList"
    prefix={
      <Skeleton
        shape="circle"
        style={{ width: 40, height: 40, borderRadius: 40 }}
      />
    }
    description={<Skeleton paragraph={{ rows: 1 }} />}
  >
    <Skeleton
      paragraph={{ rows: 1 }}
      style={{ width: "50%", height: 20, marginBottom: 10 }}
    />
    <Skeleton paragraph={{ rows: 1 }} style={{ width: "30%", height: 20 }} />
  </List.Item>
);

const WhatsappFlow = ({ userData }) => {
 const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [whatsappFlowData, setWhatsappFlowData] = useState([]);
  const listRef = useRef(null);


  const ChatItem = ({ item }) => {
    return (
      <List.Item
        key={item.id}
        className={`teamList`}
        onClick={() => navigate(`/flow_response/${item.id}`)}
        prefix={
          <div className="customer-avatar">
            {item.name ? item.name[0].toUpperCase() : ""}
          </div>
        }
        description={
          <div>
              <div>{truncateNotes(item.name)}</div>
              <div>{item.id}</div>
          </div>
        }
      >
        <span
        className="listTime"
          style={{
            backgroundColor: getStatusStyles(item.status).backgroundColor,
            color: getStatusStyles(item.status).color,
            padding:'0 10px'
          }}
        >
          {getStatusStyles(item.status).text}
        </span>
      </List.Item>
    );
  };

  const fetchData = useCallback(async () => {
    if (!userData) return;
    setLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          whatsappaccesstoken: userData?.whatsappAccessToken,
          wabaid: userData?.wabaid,
        },
      };
      const response = await fetch(
        "https://connectby.io:3001/api/get-flows",
        requestOptions
      );
      const data = await response.json();
      if (data) {
        setWhatsappFlowData(data?.responseData?.data);
        setHasMore(false);
      } else {
        Toast.show({ content: "Failed to fetch data", duration: 2000 });
      }
    } catch (error) {
      console.log(error);
      Toast.show({ content: `Error: ${error.message}`, duration: 2000 });
    } finally {
      setLoading(false);
    }
  }, [userData]);

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 800),
    [fetchData]
  );

  useEffect(() => {
    debouncedFetchData();
  }, [debouncedFetchData]);

  return (
    <div style={{ height: "calc(100vh - 50px)" }}>
      <div
        ref={listRef}
        style={{ height: "calc(100vh - 50px)", overflowY: "scroll" }}
      >
        <List>
          {whatsappFlowData.map((item) => (
            <ChatItem key={item.team_id} item={item} />
          ))}
          {hasMore && (
            <div className="loading">
              {Array.from({ length: 10 }).map((_, index) => (
                <SkeletonItem key={index} />
              ))}
            </div>
          )}
        </List>
        {!hasMore && <div className="no-more">No more data</div>}
      </div>
    </div>
  );
};

export default WhatsappFlow;
